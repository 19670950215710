import { Link } from "gatsby"
import React from "react"

type Breadcrumb = { label: string; url: string }

export const Breadcrumb: React.FC<{
  breadcrumbs: Breadcrumb[]
}> = ({ breadcrumbs }) => (
  <nav
    className="flex overflow-auto bg-white border-b border-gray-200"
    aria-label="Breadcrumb"
  >
    <ol className="flex w-full max-w-screen-xl px-4 mx-auto space-x-4 sm:px-6 lg:px-8">
      <li className="flex">
        <div className="flex items-center">
          <Link to="/" className="text-gray-400 hover:text-gray-500">
            <svg
              className="flex-shrink-0 w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <span className="sr-only">Accueil</span>
          </Link>
        </div>
      </li>

      {breadcrumbs.map(({ url, label }, index) => (
        <li className="flex" key={url}>
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-6 h-full text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            {index === breadcrumbs.length - 1 ? (
              <div className="pr-4 ml-4 text-sm font-medium text-gray-400 whitespace-nowrap">
                {label}
              </div>
            ) : (
              <Link
                to={url}
                className="ml-4 text-sm font-medium text-gray-500 whitespace-nowrap hover:text-gray-700"
              >
                {label}
              </Link>
            )}
          </div>
        </li>
      ))}
    </ol>
  </nav>
)